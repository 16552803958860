import * as React from 'react';
import { useSelector } from 'react-redux';
import { ConsultationActions } from 'features/ruerupProfiCheck/consultation/consultationSlice';
import MaterialTable, { MTableToolbar } from 'material-table';
import { RootState, useAppDispatch } from 'app/store';
import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Icon,
    IconButton,
    Switch, Tooltip,
    Typography
} from '@material-ui/core';
import IconFilter from '@material-ui/icons/SettingsOutlined';
import DrawerRightScaffold from 'app/components/scaffold/DrawerRightScaffold';
import IconCompare from '@material-ui/icons/CompareArrows';
import IconPrint from '@material-ui/icons/Print';
import { hasGreenName } from 'features/ruerupProfiCheck/consultation/questions/domain/GreenNameColor';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';

import {
    defaultCellStyle,
    defaultHeaderStyle,
    getBeitragspauseCellStyle,
    getBesserungsoptionCellStyle,
    getGarantierteRenteCellStyle,
    getGarantierterRentenfaktorCellStyle,
    getEffektivkostenCellStyle,
    getVerschiebungRentenbeginnCellStyle,
    getMaxRGZCellStyle,
    getVKRenteCellStyle,
    getMindestBeitragCellStyle,
    getMindestLaufzeitCellStyle,
    getBUCellStyle,
    getSchiebeReglerCellStyle,
    getHinterbliebenenSchutzCellStyle,
    getExklusivAnlageCellStyle,
    getPhysischesGoldCellStyle,
    getAnbieterwechselCellStyle,
    getFondsrenteCellStyle,
    getSolvencyCellStyle
} from 'features/ruerupProfiCheck/consultation/result/domain/CellColors';
import TableTooltip from 'features/ruerupProfiCheck/consultation/result/components/TableTooltip';

import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import {
    getNewContracts,
    getOldContracts,
    getValidityString, orderContracts
} from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractUtils';
import { ContractsActions, getContractIdsToCompare, getContracts } from 'features/ruerupProfiCheck/insurance_contracts/contractsSlice';
import { TableHeadersWithoutNewline } from 'features/ruerupProfiCheck/consultation/models/TableHeaders';
import LongPressListener from 'features/ruerupProfiCheck/consultation/result/components/ContractNameCell';
import OpaqueButton from 'app/components/buttons/OpaqueButton';
import ContractsOverviewPDFButton from 'features/ruerupProfiCheck/insurance_contracts/overview/pdf/ContractsOverviewPDFButton';
import { useState } from 'react';
import useWindowDimensions from 'utils/useWindowDimensions';


const ContractsOverviewPage: React.FC = () => {

    const { height, width } = useWindowDimensions();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [currentPageNumber, setCurrentPageNumber] = useState(1);

    const [showEffektivkosten, setShowEffektivkosten] = useState(true);
    const [showExklusivAnlage, setShowExklusivAnlage] = useState(true);
    const [showPhysischesGold, setShowPhysischesGold] = useState(true);
    const [showAnbieterWechsel, setShowAnbieterWechsel] = useState(true);
    const [showFondsrente, setShowFondsrente] = useState(true);

    const [sucheVal, setSucheVal] = useState('');

    const [showValidity, setShowValidity] = useState(true);


    const contractsFilter = useSelector((state: RootState) => state.contractsRuerup.filter);

    const allContracts = useSelector(getContracts);

    const isiOSWeb = (history && history.location && history.location.pathname.includes('ios/'));
    // console.info(history.location.pathname.includes('ios/'))

    let contracts: Array<InsuranceContractModel>;
    if (contractsFilter.new && !contractsFilter.old) {
        contracts = getNewContracts(allContracts);
    } else if (contractsFilter.old && !contractsFilter.new) {
        contracts = getOldContracts(allContracts);
    } else {
        contracts = allContracts.ids.map((id) => allContracts.entities[id]);
    }

    contracts = orderContracts(contracts);

    /**
     * COMPARE CONTRACTS
     */
    const contractsToCompareIds = useSelector(getContractIdsToCompare);
    const compareContractsEnabled = useSelector((state: RootState) => state.currentConsultation.compareProvidersEnabled);
    const onCompareIconPressed = () => {
        dispatch(ConsultationActions.toggleCompareProvidersEnabled());
        resetContractToCompareIds();
    };
    const setContractsToCompareIds = (ids: InsuranceContractModel[]) => {
        dispatch(ContractsActions.setContractIdsToCompare(ids));
    };
    const resetContractToCompareIds = () => {
        dispatch(ContractsActions.resetContractIdsToCompare());
    };

    const contractsData = contracts.map((o) => {
        if (contractsToCompareIds.includes(o.id)) {
            return { ...o, tableData: { checked: true } };
        }

        // if (isiOSWeb && sucheVal !== 'Suchen' && sucheVal !== '') {
        //     if (o.name.toLowerCase().includes(sucheVal.toLowerCase())) {
        //         console.info(o)
        //         return { ...o };
        //     }
        // } else {
        return { ...o };
        // }
    });

    const onShowValidityChanged = () => setShowValidity(!showValidity);


    const onShowEffektivkostenChange = () => {
        setShowEffektivkosten(!showEffektivkosten)
    };

    // const onShowExklusivAnlageChange = () => {
    //     setShowExklusivAnlage(!showExklusivAnlage)
    // };

    // const onShowPhysischesGoldChange = () => {
    //     setShowPhysischesGold(!showPhysischesGold)
    // };

    const onShowAnbieterWechselChange = () => {
        setShowAnbieterWechsel(!showAnbieterWechsel)
    };

    // const onShowFondsrenteChange = () => {
    //     setShowFondsrente(!showFondsrente)
    // };


    const drawerContent = (
        <div style={{ padding: '8px 16px' }}>
            <Typography style={{ marginTop: '12px' }} color={'primary'}>
                Anzeige
            </Typography>
            <Divider style={{ marginBottom: '8px' }} />
            <FormGroup>
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showEffektivkosten}
                        onChange={onShowEffektivkostenChange} />}
                    label="Effektivkosten"
                />
                {/*                 <FormControlLabel
                    control={<Switch color={'primary'} checked={showExklusivAnlage}
                        onChange={onShowExklusivAnlageChange} />}
                    label="Exklusiv Anlage"
                /> */}
                {/*                 <FormControlLabel
                    control={<Switch color={'primary'} checked={showPhysischesGold}
                        onChange={onShowPhysischesGoldChange} />}
                    label="Physisches Gold"
                /> */}
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showAnbieterWechsel}
                        onChange={onShowAnbieterWechselChange} />}
                    label="Anbieterwechsel"
                />
                {/*                 <FormControlLabel
                    control={<Switch color={'primary'} checked={showFondsrente}
                        onChange={onShowFondsrenteChange} />}
                    label="Fondsrente"
                /> */}
                <FormControlLabel
                    control={<Switch color={'primary'} checked={showValidity} onChange={onShowValidityChanged} />}
                    label="Gültigkeit" />
            </FormGroup>
        </div>
    );

    var tableRef: React.RefObject<any> = React.createRef();

    const print = () => {
        // var content = document.getElementById("table-print");
        // var pri = (document.getElementById("ifmcontentstoprint")! as HTMLIFrameElement).contentWindow;
        // if (pri) {
        //     pri.document.open();
        //     pri.document.write((content!.childNodes[0].childNodes[1] as HTMLElement).innerHTML);
        //     pri.document.close();
        //     pri.focus();
        //     pri.print();
        // }
        window.print();
    };

    const actions = (
        <>
            <Tooltip title="Tarife vergleichen">
                <IconButton onClick={onCompareIconPressed}>
                    <IconCompare color={compareContractsEnabled ? 'primary' : 'action'} />
                </IconButton>
            </Tooltip>
            <ContractsOverviewPDFButton contracts={contracts} isIOS={isiOSWeb} />
            <ButtonGroup orientation={'horizontal'} variant="text"
                aria-label="contained primary button group">
                <Button color={contractsFilter.new && !contractsFilter.old ? 'primary' : 'default'}
                    onClick={() => dispatch(ContractsActions.setFilter({ old: false, new: true }))}>
                    Aktuelle Tarife
                </Button>
                <Button color={contractsFilter.old && !contractsFilter.new ? 'primary' : 'default'}
                    onClick={() => dispatch(ContractsActions.setFilter({ old: true, new: false }))}>
                    Alttarife
                </Button>
                <Button color={contractsFilter.new && contractsFilter.old ? 'primary' : 'default'}
                    onClick={() => dispatch(ContractsActions.setFilter({ old: true, new: true }))}>
                    Alle Tarife
                </Button>
            </ButtonGroup>
            {
                (isiOSWeb)
                    ? <input
                        id={'iossearch'}
                        type={'search'}
                        value={sucheVal}
                        placeholder={'Suchen'}
                        onClick={() => { if (sucheVal === 'Suchen') { setSucheVal('') } }}
                        onChange={(e) => {
                            setSucheVal(e.target.value);
                            // console.log('suchen', sucheVal, tableRef);
                            if (tableRef) {
                                tableRef.current.dataManager.changeSearchText(e.target.value);
                                tableRef.current.setState({ searchText: e.target.value });
                                tableRef.current.setState(tableRef.current.dataManager.getRenderState());
                            }

                            // const collection = ;
                            // console.log(collection[0])
                            // (document.getElementsByClassName("MuiInputBase-input")[0] as HTMLInputElement).value = e.target.value;
                        }}
                        style={{
                            border: 'none',
                            borderBottom: 'none',
                            paddingLeft: 5,
                            maxWidth: 200,
                            textAlign: 'left',
                            height: 36,
                            marginRight: 10,
                            marginLeft: 20,
                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 500,
                            backgroundColor: '#fcfcfc', // f2f2f2
                            outline: 'none',
                            fontSize: '0.975rem',
                            color: 'black'
                        }} />
                    : ''
            }
            {/* {
                (isiOSWeb)
                    ?
                    <IconButton onClick={() => { print() }}>
                        <IconPrint color={'action'} />
                    </IconButton> : ''
            } */}
        </>
    );

    const TOOLBAR_ID = "some__unique__hacky__id";

    return (
        <DrawerRightScaffold
            key={'ruerup-overview'}
            fullWidth={true}
            title={!isiOSWeb ? 'Tarifübersicht' : ''}
            openDrawerIcon={<IconFilter />}
            drawerContent={drawerContent}
            onNavigateBack={() => {
                if (compareContractsEnabled) {
                    dispatch(ContractsActions.resetContractIdsToCompare());
                    dispatch(ConsultationActions.toggleCompareProvidersEnabled());
                }
            }}
            withBackNavigation={!isiOSWeb}
            actions={actions}
        >
            <iframe title='print' id="ifmcontentstoprint" style={{ height: '0px', width: '0px', position: 'absolute', border: 0 }}></iframe>
            <div
                id="table-print"
                style={(!isiOSWeb) ? { maxWidth: '100vw' } : { maxWidth: '100vw', minHeight: '80vh', position: 'absolute', top: '80px' }}>
                <MaterialTable<InsuranceContractModel>
                    tableRef={tableRef}
                    page={currentPageNumber}
                    onChangePage={(newPageNumber) => {
                        setCurrentPageNumber(newPageNumber);
                    }}
                    data={contractsData}
                    title={`${contractsData.length} Tarife (${contractsFilter.new && contractsFilter.old ? 'Alle Tarife' : contractsFilter.old ? 'Alttarife' : 'Aktuelle Tarife'})`}
                    localization={{
                        toolbar: {
                            searchTooltip: 'Suchen',
                            searchPlaceholder: 'Suchen',
                            nRowsSelected: '{0}/2 Tarif(e) ausgewählt'
                        },
                        pagination: {
                            labelRowsSelect: 'Tarife',
                            labelRowsPerPage: 'Tarife pro Seite'
                        },
                        body: {
                            emptyDataSourceMessage: 'Keine Tarife vorhanden'
                        },
                    }}
                    onSelectionChange={(rows) => {
                        if (rows.length - contractsToCompareIds.length > 1) {
                            return;
                        }
                        setContractsToCompareIds(rows);
                    }}
                    options={{
                        draggable: false,
                        sorting: false,
                        selection: compareContractsEnabled,
                        padding: 'dense',
                        pageSize: 10, //editableContracts.length,
                        paginationType: 'stepped',
                        pageSizeOptions: [5, 10, 20, 50],
                        maxBodyHeight: height - 250,
                        minBodyHeight: '70vh',
                        search: true,
                        searchText: sucheVal,
                        headerStyle: {
                            background: 'white',
                            borderBottom: '1 px solid gray',
                            position: 'sticky',
                            top: 0,
                            fontSize: 11,
                            zIndex: 85
                        },
                        actionsCellStyle: {
                            backgroundColor: '#dbdbdb',
                            color: 'black'
                        },
                        searchFieldStyle: {
                            userSelect: 'text',
                            display: (!isiOSWeb) ? 'inline-flex' : 'none'
                            // backgroundColor: 'black'
                        },
                        selectionProps: (rowData: any) => ({
                            disabled: contractsToCompareIds.length >= 2 && !rowData.tableData.checked,
                            color: 'primary'
                        })
                        //grouping: true
                    }}
                    components={{
                        Container: (props) => <div
                            style={{ width: width - 48, height: height - 200, minHeight: '80vh' }}>{props.children}</div>,
                        Toolbar: props => {
                            return (
                                <div id={TOOLBAR_ID} style={{
                                    userSelect: 'initial'
                                }}
                                // onClick={() => {
                                //     if (!isiOSWeb) {
                                //         return;
                                //     }
                                //     // console.info('show ijput')
                                //     const collection = document.getElementsByClassName("MuiInputBase-input");
                                //     if (collection) {
                                //         console.info('show ', collection[0]);
                                //         (collection[0] as HTMLInputElement).setAttribute('autofocus', 'autofocus');
                                //         (collection[0] as HTMLInputElement).onblur = function () {
                                //             (collection[0] as HTMLInputElement).focus();
                                //             console.log('focus 1')
                                //         };
                                //         // (collection[0] as HTMLInputElement).focus();
                                //         // document.querySelector<HTMLInputElement>(`input`)?.focus()
                                //         // setTimeout(function () {
                                //         //     (collection[0] as HTMLInputElement).focus();
                                //         //     document.querySelector<HTMLInputElement>(`input`)?.focus();
                                //         //     console.info('show timer', collection[0]);
                                //         // }, 2000);
                                //     }
                                // }}
                                >
                                    <MTableToolbar {...props} />
                                </div>
                            );
                        }
                    }}
                    columns={[
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[0].title, TableHeadersWithoutNewline[0].comment),
                            cellStyle: {
                                background: 'white',
                                borderRight: '1 px solid gray',
                                position: 'sticky',
                                left: 0,
                                zIndex: 80
                            },
                            headerStyle: {
                                background: 'white',
                                borderRight: '1 px solid gray',
                                position: 'sticky',
                                left: 0,
                                zIndex: 90,
                                fontSize: 11
                            },
                            field: 'name',
                            render: (contract) => {
                                return (
                                    <LongPressListener
                                        key={contract.id}
                                        callback={() => {
                                            history.push((
                                                !isiOSWeb
                                                    ? Routes.RuerupProfiCheck.Contracts.DetailView
                                                    : Routes.RuerupProfiCheck.Contracts.DetailView.replace('app/', 'ios/')
                                            ) + '/' + contract.id);
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingLeft: 8,
                                                marginLeft: -12,
                                                cursor: 'pointer'
                                            }}
                                        >
                                            {renderCellWithTooltip(
                                                contract.name,
                                                contract.nameCom,
                                                {
                                                    color: hasGreenName(contract) ? 'green' : 'black',
                                                    width: '150pt'
                                                } as React.CSSProperties,
                                                'left'
                                            )}
                                        </div>
                                    </LongPressListener>
                                );
                            }
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[1].title, TableHeadersWithoutNewline[1].comment),
                            field: 'vertragsart',
                            cellStyle: defaultCellStyle as React.CSSProperties,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.vertragsart, contract.vertragsartCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[2].title, TableHeadersWithoutNewline[2].comment),
                            field: 'garantierteRente',
                            cellStyle: getGarantierteRenteCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.garantierteRente, contract.garantierteRenteCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[3].title, TableHeadersWithoutNewline[3].comment),
                            field: 'garantierterRentenfaktor',
                            cellStyle: getGarantierterRentenfaktorCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.garantierterRentenfaktor, contract.garantierterRentenfaktorCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[4].title, TableHeadersWithoutNewline[4].comment),
                            field: 'besserungsoption',
                            cellStyle: getBesserungsoptionCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.besserungsoption, contract.besserungsoptionCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[5].title, TableHeadersWithoutNewline[5].comment),
                            field: 'beitragspause',
                            cellStyle: getBeitragspauseCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.beitragspause, contract.beitragspauseCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[6].title, TableHeadersWithoutNewline[6].comment),
                            field: 'verschiebungRentenbeginn',
                            cellStyle: getVerschiebungRentenbeginnCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.verschiebungRentenbeginn, contract.verschiebungRentenbeginnCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[7].title, TableHeadersWithoutNewline[7].comment),
                            field: 'hinterbliebenenSchutz',
                            cellStyle: getHinterbliebenenSchutzCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.hinterbliebenenSchutz, contract.hinterbliebenenSchutzCom)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[8].title, TableHeadersWithoutNewline[8].comment),
                            field: 'effektivkosten',
                            cellStyle: getEffektivkostenCellStyle,
                            headerStyle: defaultHeaderStyle,
                            hidden: !showEffektivkosten,
                            render: (contract) =>
                                renderCellWithTooltip(
                                    contract.effektivkosten.toString() === '?' ? '?' : contract.effektivkosten + '%',
                                    contract.effektivkostenCom
                                )
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[9].title, TableHeadersWithoutNewline[9].comment),
                            field: 'vkRente',
                            cellStyle: getVKRenteCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) =>
                                renderCellWithTooltip(contract.vkRente.toString() === 'unbek.' ? 'unbek.' : contract.vkRente + '%', contract.vkRenteCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[10].title, TableHeadersWithoutNewline[10].comment),
                            field: 'mindestbeitrag',
                            cellStyle: getMindestBeitragCellStyle,
                            headerStyle: defaultHeaderStyle,

                            render: (contract) =>
                                renderCellWithTooltip(
                                    contract.mindestbeitrag.toString() === '?' || contract.mindestbeitrag.toString() === '' ? '?' : contract.mindestbeitrag + ' €',
                                    contract.mindestbeitragCom
                                )
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[11].title, TableHeadersWithoutNewline[11].comment),
                            field: 'mindestlaufzeit',
                            cellStyle: getMindestLaufzeitCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.mindestlaufzeit, contract.mindestlaufzeitCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[12].title, TableHeadersWithoutNewline[12].comment),
                            field: 'bu',
                            cellStyle: getBUCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.bu, contract.buCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[13].title, TableHeadersWithoutNewline[13].comment),
                            field: 'schieberegler4fach',
                            cellStyle: getSchiebeReglerCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.schieberegler4fach, contract.schieberegler4fachCom)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[14].title, TableHeadersWithoutNewline[14].comment),
                            field: 'exklusivAnlage',
                            cellStyle: getExklusivAnlageCellStyle,
                            hidden: !showExklusivAnlage,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.exklusivAnlage, contract.exklusivAnlageCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[15].title, TableHeadersWithoutNewline[15].comment),
                            field: 'physischesGold',
                            cellStyle: getPhysischesGoldCellStyle,
                            hidden: !showPhysischesGold,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.physischesGold, contract.physischesGoldCom)
                        },
                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[16].title, TableHeadersWithoutNewline[16].comment),
                            field: 'fondsrente',
                            cellStyle: getFondsrenteCellStyle,
                            hidden: !showFondsrente,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.fondsrente, contract.fondsrenteCom)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[17].title, TableHeadersWithoutNewline[17].comment),
                            field: 'solvency2',
                            cellStyle: getSolvencyCellStyle,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.solvency2, contract.solvency2Com)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[18].title, TableHeadersWithoutNewline[18].comment),
                            field: 'bemerkung',
                            cellStyle: defaultCellStyle,
                            hidden: !showFondsrente,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.bemerkung, contract.bemerkungCom)
                        },

                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[19].title, TableHeadersWithoutNewline[19].comment),
                            field: 'anbieterwechsel',
                            cellStyle: getAnbieterwechselCellStyle,
                            hidden: !showAnbieterWechsel,
                            headerStyle: defaultHeaderStyle,
                            render: (contract) => renderCellWithTooltip(contract.anbieterwechsel, contract.anbieterwechselCom)
                        },


                        {
                            title: renderHeaderWithTooltip(TableHeadersWithoutNewline[20].title, TableHeadersWithoutNewline[20].comment),
                            cellStyle: defaultCellStyle,
                            headerStyle: defaultHeaderStyle,
                            hidden: !showValidity,
                            render: (contract: InsuranceContractModel) => <div>{getValidityString(contract)}</div>
                        }
                    ]}
                />

                <Box display={compareContractsEnabled ? 'flex' : 'none'} style={{ width: '100%', marginTop: -3 }}>
                    <Grid container direction={'row'} spacing={4}>
                        <Grid item md={6}>
                            <OpaqueButton
                                disabled={contractsToCompareIds.length !== 2}
                                onClick={() => history.push((
                                    !isiOSWeb
                                        ? Routes.RuerupProfiCheck.Contracts.Compare
                                        : Routes.RuerupProfiCheck.Contracts.Compare.replace('app/', 'ios/')
                                ))}
                                startIcon={<Icon>compare_arrows</Icon>}
                                size={'large'}
                                fullWidth
                                variant={'contained'}
                                color={'primary'}
                            >
                                Vergleichen
                            </OpaqueButton>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </DrawerRightScaffold >
    );
};

const renderCellWithTooltip = (
    content: string | number,
    comment: string | number,
    contentStyle?: React.CSSProperties | undefined,
    align?: 'center' | 'left'
) => {
    content = content.toString();
    comment = comment.toString();

    contentStyle = {
        ...contentStyle,
        height: '28pt',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: align ?? 'center',
        paddingTop: (comment !== '' ? '0px' : (((align ?? 'center') === 'center') ? '10px' : '0px'))
    };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (
        <div style={contentStyle}>{content}</div>
    );
};

const renderHeaderWithTooltip = (content: string | number, comment: string | number, align?: 'center' | 'left') => {
    content = content.toString();
    comment = comment.toString();

    const contentStyle = { flexGrow: 1, height: '100%' };
    return comment !== '' ? (
        <TableTooltip tooltipValue={comment}>
            <div style={contentStyle}>{content}</div>
        </TableTooltip>
    ) : (
        <div style={contentStyle}>{content}</div>
    );
};

export default ContractsOverviewPage;
