import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import contractData from 'assets/ruerupData-20250311.json';
import InsuranceContractModel from 'features/ruerupProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { NormalizedObjects } from 'features/ruerupProfiCheck/customers/customerSlice';
import { normalize } from 'normalizr';
import { schema } from 'normalizr';
import { RootState } from 'app/store';

const contractSchema = new schema.Entity<InsuranceContractModel>('insuranceContracts');
const contractArraySchema = new schema.Array<InsuranceContractModel>(contractSchema);
const initialNormalizedData = normalize(contractData, contractArraySchema);

export interface ContractsRuerup extends NormalizedObjects<InsuranceContractModel> {}

export interface ContractsStateRuerup {
    entities: { [id: string]: InsuranceContractModel };
    ids: string[];
    contractIdsToCompare: string[];
    filter: ContractsFilter
}

export interface ContractsFilter {
    old: boolean,
    new: boolean
}

const initialState: ContractsStateRuerup = {
    entities: initialNormalizedData.entities.insuranceContracts as { [id: string]: InsuranceContractModel },
    ids: initialNormalizedData.result,
    contractIdsToCompare: [],
    filter: {
        old: false,
        new: true
    }
};

export const contractsSliceRuerup = createSlice({
    name: 'contracts',
    initialState: initialState,
    reducers: {
        setContracts: (state: ContractsRuerup, action: PayloadAction<ContractsRuerup[]>) => {
            const normalizedData = normalize(action.payload, contractArraySchema);
            state.entities = normalizedData.entities.insuranceContracts as { [id: string]: InsuranceContractModel };
            Object.keys(normalizedData.entities);
        },
        resetContractIdsToCompare: (state: ContractsStateRuerup) => {
            state.contractIdsToCompare = [];
        },
        setContractIdsToCompare: (state: ContractsStateRuerup, action: PayloadAction<Array<InsuranceContractModel>>) => {
            state.contractIdsToCompare = action.payload.map((contract: InsuranceContractModel) => contract.id);
        },
        setFilter: (state: ContractsStateRuerup, action: PayloadAction<ContractsFilter>) => {
            return { ...state, filter: {...state.filter, ...action.payload}};
        },
        overwriteContracts: (state: ContractsStateRuerup, action: PayloadAction<ContractsStateRuerup>) => {
            state.contractIdsToCompare = action.payload.contractIdsToCompare
            state.entities = action.payload.entities
            state.filter = action.payload.filter
            state.ids = action.payload.ids
        },
    }
});
export const getContracts = (state: RootState) => state.contractsRuerup;

export const getContractIdsToCompare = (state: RootState) => state.contractsRuerup.contractIdsToCompare;
export const getContractsToCompare = createSelector(
    (state: RootState) => {
        return [state.contractsRuerup.entities[state.contractsRuerup.contractIdsToCompare[0]], state.contractsRuerup.entities[state.contractsRuerup.contractIdsToCompare[1]]];
    },
    (contractsRuerup) => contractsRuerup
);

export const ContractsActions = contractsSliceRuerup.actions;
export default contractsSliceRuerup.reducer;
