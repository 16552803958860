import * as React from 'react';
import { Button, ButtonBase, Grid, List, Paper, Typography } from '@material-ui/core';
import InsuranceContractModel from 'features/riesterProfiCheck/insurance_contracts/models/InsuranceContractModel';
import { ConsultationActions, questionFilteredContracts as questionFilteredContractsSelector } from 'features/riesterProfiCheck/consultation/consultationSlice';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'app/store';
import { useHistory } from 'react-router-dom';
import Routes from 'app/routes/Routes';
import { hasGreenName } from 'features/riesterProfiCheck/consultation/questions/domain/GreenNameColor';

const InsuranceContractList: React.FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const questionFilteredContracts = useSelector(questionFilteredContractsSelector);
    const questionValues = useSelector((state: RootState) => state.currentConsultation.questionValues);
    const selectedContractIds = useSelector((state: RootState) => state.currentConsultation.selectedContractIds);

    // console.info(questionValues.includes(true))

    return (
        <Grid item container direction={'column'} md={3} xs={12}>
            <Paper style={{ maxHeight: '5vh', padding: '6px', textAlign: 'center' }}>
                <Typography variant={'subtitle2'}>
                    {questionFilteredContracts.length}
                    {questionFilteredContracts.length === 0 || questionFilteredContracts.length > 1 ? ' Tarife' : ' Tarif'}
                </Typography>
            </Paper>
            <Paper style={{ overflow: 'auto', flexGrow: 1, marginTop: '2vh' }}>
                <List style={{ padding: 'none', maxHeight: '65vh' }}>
                    {questionFilteredContracts.map((contract: InsuranceContractModel) => {
                        return (
                            <ButtonBase
                                key={contract.id}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    dispatch(ConsultationActions.toggleSelectContract(contract));
                                }}
                            >
                                <Grid container direction="row">
                                    <div
                                        style={{
                                            background: selectedContractIds.indexOf(contract.id) !== -1 ? 'blue' : 'transparent',
                                            width: '6px'
                                        }}
                                    ></div>

                                    <p
                                        style={{
                                            padding: '8px',
                                            margin: '0px',
                                            color: questionValues[2] && hasGreenName(contract) ? 'green' : 'black'
                                        }}
                                    >
                                        {contract.name}
                                    </p>
                                </Grid>
                            </ButtonBase>
                        );
                    })}
                </List>
            </Paper>
            <Button
                disabled={selectedContractIds.length === 0 && questionFilteredContracts.length > 0} //  && !questionValues.includes(true)
                variant={'outlined'}
                color={'primary'}
                style={{ marginTop: '2vh' }}
                onClick={() => history.push(Routes.Consultation.Result)}
            >
                {
                    // (questionFilteredContracts.length > 0 && selectedContractIds.length > 0) ? <div>Selektion Anzeigen ({selectedContractIds.length})</div> : <div>Weiter ohne Auswahl</div>
                    <div>Selektion Anzeigen ({selectedContractIds.length})</div>
                }
            </Button>
        </Grid>
    );
};

export default InsuranceContractList;
