import * as React from 'react';
import { Typography } from '@material-ui/core';
import NavigateBackScaffold from 'app/components/scaffold/NavigateBackScaffold';

const InformationsPage: React.FC = () => {

    return (
        <NavigateBackScaffold title={'Informationen'} actions={<></>}>
            <>
                <Typography variant={'h4'}>Vorsorge-Profi-Check</Typography>
                <br />
                <Typography variant={'h5'}>
                    Der Vorsorge-Profi-Check ist ein Analyse- und Beratungstool für die Altersvorsorgeberatung.
                </Typography>
                <Typography variant={'body1'}>
                    <br />
                    Der „Vorsorge-Profi-Check“ beinhaltet u.a. den „Riester-Profi-Check“ mit allen im Markt für das Neugeschäft angebotenen Riester-Tarifen aus dem Bank-, Investment-, Bauspar- und Versicherungsbereich und unzähligen Bestandstarifen sowie den „Rürup-Profi-Check“ für Basisrenten mit einer ebenfalls sehr umfassenden Tarifdatenbank von Neugeschäfts- und Bestandstarifen.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“ macht es dem Verbraucher leicht, mit den „10 Goldenen Riester-Fragen“ - und möglicherweise künftig auch mit den „Goldenen Rürup-Fragen“? - ausgehend von seinen persönlichen Auswahlkriterien den Tarif zu finden, der seinen Wünschen und Anforderungen am besten entspricht.
                    <br />
                    <br />
                    Der Berater kann das Beratungsergebnis personalisiert abspeichern, drucken oder versenden. Zu jedem Zeitpunkt kann das gespeicherte Ergebnis wieder aufrufen und sogar neu bearbeitet werden.
                    <br />
                    <br />
                    Die „Profi-Checks“ orientieren sich ausschließlich an den Interessen des Verbrauchers. Sie sind in ihrer Art einzigartig und zählen aktuell sicherlich zu den besten Analyse- und Beratungstools für Vorsorgeberater für Riester- und Basisrenten.
                    <br />
                    <br />
                    Der „Vorsorge-Profi-Check“, der „Riester-Profi-Check“, die „10 Goldenen Riester-Fragen“, die „Goldene Riester-Pyramide“ (nur für iOS) und der „Rürup-Profi-Check“ sind ein Markenzeichen der „DFIAV - Deutsche Förderinitiative Altersvorsorge UG“ und unterliegen dem Schatz des Urheberrechtes.
                    <br />
                    <br />
                    Ebenso bietet der „Vorsorge-Profi-Check“ zur Beratungsunterstützung weitere nützliche „Beratungstools“, u.a. die „Goldene Riester-Pyramide“ (exklusiv für die iOS-App) und bietet wichtige Unterstützung zum Thema „Solvency II"
                </Typography>
                <br />
                <br />
                <Typography variant={'h5'}>Tarifvergleiche</Typography>
                <br />
                <Typography variant={'body1'}>
                    Die Tarifvergleiche ermöglichen einen guten Überblick über die wichtigsten Leistungsmerkmale der ausgewählten Tarife.<br />
                    <br />
                    Bei den Leistungsmerkmalen dieser Vergleichstarife wird in der Kundenberatung zum Beispiel vom „Riester-Profi-Check“ zunächst überprüft, ob die „10 Goldenen Riester-Fragen“ ganz oder teilweise erfüllt werden.<br />
                    <br />
                    Zudem können folgende Informationen gewonnen werden:<br />
                </Typography>
                <ul className={'body1'}>
                    <li>Um welche Tarif- bzw. Vertragsart handelt es sich?</li>
                    <li>Höhe der Garantierente laut Produktinformationsblatt (PIB)</li>
                    <li>Höhe des garantierten Rentenfaktors lauf PIB</li>
                    <li>Effektivkosten in der Ansparphase laut PIB</li>
                    <li>Verwaltungskosten in der Verrentungsphase laut PIB</li>
                    <li>Mindestbeitrag</li>
                    <li>Mindestlaufzeit</li>
                    <li>Informationen zu den Solvency II Quoten</li>
                    <li>sonstige wichtige Besonderheiten zu einzelnen Tarifen</li>
                </ul>
                <Typography variant={'body1'}>
                    <br />
                    Es wird ausdrücklich darauf hingewiesen, dass die Informationen nicht vollumfassend sind.<br />
                    Die genauen Vertrags- und und Tarifinhalte ergeben sich aus dem individuellen Antrag, dem Versicherungsschein sowie den Versicherungsbedingungen und ggf. besonderen Bedingungen des Kunden.<br />
                    <br />
                    Der „Vorsorge-Profi-Check“ wird laufend aktualisiert und steht den Nutzern durch regelmäßige Updates immer relativ aktuell zur Verfügung. Bei jedem Start des „Vorsorge-Profi-Checks“ wird automatisch überprüft ob inzwischen eine aktuellere Version vorliegt und es erfolgt in der Regel ein automatisches Update oder aber ein Hinweis zu einem Update um dieses durchzuführen.
                    <br />
                    <br />
                    Trotz aller Sorgfalt können sich Tarifdaten inzwischen auch verändert haben.
                </Typography>
                <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    Eine Haftung für Aktualität, Richtigkeit und Vollständigkeit kann daher nicht übernommen werden.
                </Typography>
                <br />
                <br />
                <Typography variant={'h5'}>Quellen</Typography>

                <ul className={'body1'}>
                    <li>Versicherungsbedingungen</li>
                    <li>Ergänzende Vertragsbedingungen</li>
                    <li>Muster-Produktinformationsblätter</li>
                    <li>Bundeszentralamt für Steuern</li>
                    <li>Homepages der Anbieter</li>
                </ul>
                <br />
                <br />
            </>

        </NavigateBackScaffold >
    );
};

export default InformationsPage;
