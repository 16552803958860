import React from 'react';
import { Page, Text, View } from '@react-pdf/renderer';
import { Column, PDFStyles, Row, Section, Whitespace } from 'core/pdf/CustomPdfComponents';
import { blue, orange } from '@material-ui/core/colors';

const Heading = (props: any) => <Text style={{ fontWeight: 'bold', fontSize: 12 }}>{props.children}</Text>;

const LegendTable = (props: { items: string[] }) => {

    const rows = () => {
        let rows = [];
        for (let i = 0; i < props.items.length; i++) {
            const row = (
                <View style={{ marginBottom: 4 }}>
                    <Row>
                        <Text style={{ width: 120 }}>{props.items[i++]}</Text>
                        <Text>{props.items[i]}</Text>
                    </Row>
                </View>
            );
            rows.push(row);
        }
        return rows;
    };

    return (
        <Column>
            <View style={{ marginRight: 40, marginBottom: 4 }}>{rows()}</View>
        </Column>
    );
};

const LegendItem = (props: { title: string; items: string[] }) => {
    return (
        <Section>
            <View style={{ marginBottom: 8 }}>
                <Heading>{props.title}</Heading>
            </View>
            <LegendTable items={props.items} />
        </Section>
    );
};

const BelowLegendItem = (props: { text: string }) => (
    <View style={{ margin: 10, marginTop: 8 }}>
        <Text>{props.text}</Text>
    </View>
);

const BelowHeadingText = (props: { text: string }) => (
    <View style={{ marginTop: 8, marginBottom: 8 }}>
        <Text>{props.text}</Text>
    </View>
);

const PdfLegend1 = (props: any) => (
    <Page size="A4" style={{ ...PDFStyles.page, paddingRight: 40 }}>
        <Section>
            <Row style={{ marginTop: 8 }}>
                <View style={{ width: 6, height: '100%', backgroundColor: blue[600], marginRight: 4 }}></View>
                <Text>{'Tarif entspricht den ausgewählten Kriterien des Kunden'}</Text>
            </Row>
            <Row style={{ marginTop: 8 }}>
                <View style={{ width: 6, height: '100%', backgroundColor: orange[600], marginRight: 4 }}></View>
                <Text>{'Tarif manuell hinzugefügt, entspricht nicht den Kriterien'}</Text>
            </Row>
            {/* <Row style={{ marginTop: 8 }}>
                <View style={{ width: 6, height: '100%', backgroundColor: 'grey', marginRight: 4 }}></View>
                <Text>{'Bestandstarif, ist nicht mehr im Neugeschäft erhältlich'}</Text>
            </Row> */}
        </Section>
        <Section>
            <Text style={{ fontWeight: 'bold', fontSize: 14 }}>{'Der Riester-Profi-Check'}</Text>
            <Text style={{ marginTop: 8 }}>{'21 Riester-Rententarife von 10 Versicherern'}</Text>
            <Text style={{ marginTop: 2 }}>{'4 Riester-Fondssparpläne von 1 Anbieter'}</Text>
            <Text style={{ marginTop: 2 }}>{'0 Riester-Banksparpläne sowie'}</Text>
            <Text style={{ marginTop: 2 }}>{'8 Riester-Bausparverträge'}</Text>
            <Text style={{ marginTop: 3, fontWeight: 'bold' }}>{'(Stand zum 16.02.2025)'}</Text>
        </Section>
        <Section>
            <Text
                style={{ fontWeight: 'bold' }}>{'Zum Vergleich dient im Riester-Profi-Check der Musterkunde mit 30 Jahren Laufzeit!'}</Text>
        </Section>
        <Section>
            <Text
                style={{ fontWeight: 'bold' }}>{'Der Riester-Profi-Check gibt einen Überblick über die wichtigsten Leistungsmerkmale der Tarife von den einzelnen Anbietern. Es handelt sich für den Verbraucher um eine wichtige Auswahl von relevanten Kriterien. Da es sich nur um die wichtigsten Kriterien handelt, sind die Informationen nicht vollumfänglich. Die vollständigen Vertragsinhalte ergeben sich aus dem Antrag, dem Versicherungsschein sowie den Vertrags- und Versicherungsbedingungen.'}</Text>
        </Section>
        <Section>
            <Text
                style={{ fontWeight: 'bold' }}>{'Der Riester-Profi-Check wird laufend geprüft und aktualisiert. Trotz aller Sorgfalt können sich die Daten inzwischen verändert haben. Eine Haftung oder gar Garantie für Aktualität, Richtigkeit und Vollständigkeit der Daten kann somit nicht übernommen werden.'}</Text>
        </Section>
        <Section>
            <Heading>Quellen:</Heading>
            <Text style={{ marginTop: 2 }}>{'Produktinformationsblätter'}</Text>
            <Text style={{ marginTop: 2 }}>{'Versicherungsbedingungen'}</Text>
            <Text style={{ marginTop: 2 }}>{'Ergänzende Vertragsbedingungen'}</Text>
            <Text style={{ marginTop: 2 }}>{'Bundeszentralamt für Steuern'}</Text>
            <Text style={{ marginTop: 2 }}>{'Homepages der Anbieter'}</Text>
        </Section>
        <Section>
            <Text style={{ fontWeight: 'bold' }}>Das Ergebnis des Riester-Profi-Check ist ausschließlich für den internen Gebrauch bestimmt!</Text>
        </Section>
        <Section>
            <Heading>{'Erklärung einiger Abkürzungen:'}</Heading>
        </Section>
        <LegendItem
            title={'a) unter Vertragsart:'}
            items={[
                'FP:',
                'Fondspolice',
                'IP:',
                'Indexpolice',
                'IP/FP:',
                'Mix aus Index- und Fondspolice',
                'KP:',
                'Kapitalpolice',
                'KP (Üb. in F.):',
                'Kapitalpolice, nur die Überschüsse werden in Fonds angelegt',
                'FSP:',
                'Fondssparplan',
                'BSP:',
                'Banksparplan',
                'BSV:',
                'Bausparvertrag'
            ]}
        />
        <View>
            <LegendItem
                title={'b) unter gar. Rentenfaktor:'}
                items={['n. f. GK bedeutet:', 'RF gilt bei diesem Anbieter nur für das Garantiekapital', '0 bedeutet:', 'es gibt keinen Rentenfaktor']}
            />

        </View>
        <View style={{ marginTop: 40, marginLeft: 10 }}>
            <Heading>{'c) Besserungsoption:'}</Heading>
            <BelowHeadingText
                text={
                    'Sollte sich zu Rentenbeginn entweder wegen besserer Sterbetafeln (als zu Vertragsbeginn kalkulíert) und/oder \n' +
                    'aufgrund besserer Markverzinsung ein höherer RF und somit eine höhere Rente ergeben, so werden diese \n' +
                    'besseren Rechnungsgrundlagen angewandt und der VN wird somit besser gestellt als zu anfangs garantiert.'
                }
            />
        </View>

        <LegendItem
            title={'d) unter max. RGZ:'}
            items={[
                'RGZ',
                'Rentengarantiezeit',
                'RKW',
                'Rückkaufswert',
                'RKW bis 85:',
                'Auszahlung des RKW nur bis zum 85. Lebensjahr',
                'RKA:',
                'Restkapitalabfindung (Verrentungskapital abzgl. bereits ausgezahlter Renten)',
                'RKA 18:',
                'Restkapitalabfindung von max. 18 Jahresrenten\n (18 Jahresrenten abzgl. bereits ausgezahlter Renten)'
            ]}
        />

        <BelowLegendItem
            text={
                'Der wesentlich Unterschied zwischen RGZ und den anderen Varianten ist, dass die Hinterbliebenen z. B. bei der\n' +
                'Generali wählen können zwischen der Rente bis zum Ende der RGZ oder der abgezinsten Auszahlung der Renten.\n' +
                'Bei den anderen Varianten wird immer ausgezahlt ohne die Wahlmöglichkeitn für eine Rentenzahlung zu haben.'
            }
        />
        <View style={{ marginTop: 30 }}>
            <Section>
                <Heading>{'e) unter Effektivkosten:'}</Heading>
                <Text style={{ marginTop: 8 }}>{'bei der Generali steht 1,72 - 2,84 % (Stand 01.01.2025)'}</Text>
                <Text>{'1,72 % gilt bei 100% Sicherheitsorientiert'}</Text>
                <Text>{'2,84 % gilt für 100 % Chancenorientiert'}</Text>
            </Section>
        </View>

        <BelowLegendItem
            text={
                'Die Spalte Effektivkosten ist standardmäßig ausgeblendet, kann jedoch bei Bedarf vom Berater eingeblendet werden.\n' +
                'Der Grund ist, dass hier die Mehrwerte für die Erfüllung der Kundenkriterien in den Vordergrund gestellt werden.'
            }
        />
        <BelowLegendItem
            text={
                'Die Effektivkosten weisen die max. jährlichen Kosten aus, bezogen auf die vereinbarte Laufzeit pro Jahr.'
            }
        />
        <Section><Text style={{ fontWeight: 'bold' }}>Nur bei den Rentenversicherungstarifen beinhalten diese Effektivkosten bereits auch schon die Kosten für das biometrische Risiko der Langlebigkleit.</Text></Section>

        <LegendItem title={'f) Vk Rente in %:'} items={['VK Rente:', 'Verwaltungskosten in der Rentenphase']} />
        <BelowLegendItem
            text={
                'Während der Rentenphase werden die Verwaltungskosten auf die gezahlte Jahresrente berechnet.'
            }
        />

        <Section>
            <Heading>{'h) BU'}</Heading>
            <BelowHeadingText text={'Nur sehr wenige Anbieter bieten die Beitragsbefreiung bei BU an. \n' +
                'Die Generali ist der einzige Anbieter, der diese BU-Beitragsbefreiung aktuell ohne Gesundheitsfragen anbietet.'} />
            <Whitespace height={6} />
        </Section>
        <View break={true} style={{ marginBottom: 40 }}></View>
        <Section>
            <Heading>{'i) unter Solvency II:'}</Heading>
            <BelowHeadingText
                text={
                    'Unterscheidung zwischen den Anbietern welche keine Übergangsmaßnahmen in Anspruch nehmen sowie \n' +
                    'denen die entweder freiwillig die 16-jährige Übergangsmaßnahme anwenden oder diese ÜM \n' +
                    'zwangsweise anwenden müssen.\n'
                }
            />
            <Text style={{ marginTop: 8, marginBottom: 8, fontWeight: 'bold', fontSize: 14 }}>{'Solvency II'}</Text>
            <Text style={{ marginTop: 8, marginBottom: 8, fontWeight: 'bold' }}>{'Kommt der Versicherer ohne Anwendung der Übergangsmaßnahmen (ÜM) aus? Ja/Nein:'}</Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>{'Ja:'}</Text>
            <Text style={{ fontWeight: 'bold' }}>{'Diese Versicherer wenden die Übergangsmaßnahmen nicht an.'}</Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>{'Nein:'}</Text>
            <Text style={{ fontWeight: 'bold' }}>{'Diese Versicherer wenden (z. B. wegen nicht ausreichender Rückstellungen für die garantierten Verpflichtungen gegenüber ihren Versicherten) die genehmigungspflichtigen Übergangsmaßnahmen für versicherungtechnische Rückstellungen nach § 352 VAG entweder freiwillig oder zwangsweise an.'}</Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>{'Es gibt im Wesentlichen 3 Solvenz-Quoten.'}</Text>
            <Text style={{ fontWeight: 'bold' }}>{'a) Netto bzw. Basis-Quote'}</Text>
            <Text style={{ fontWeight: 'bold' }}>{'b) Quote mit Volatilitätsanpassung (VA)'}</Text>
            <Text style={{ fontWeight: 'bold' }}>{'c) Quote mit Übergangsmaßnahmen (ggf. zus. mit VA)'}</Text>
        </Section>
        <Section>
            <Text style={{ fontWeight: 'bold' }}>
                {'a) Die Netto- bzw. Basis-Quote zeigt die Überdeckung über den gesetzlich notwendigen Kapitalanforderungen unter Solvency II ohne Berücksichtigung einer möglichen Volatilitätsanpassung oder von Übergangsmaßnahmen.'}
            </Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>
                {'Der Umstieg von Solvency I auf Solvency II sah außerdem dauerhafte Anpassungen oder befristete Übergangsregelungen vor:'}
            </Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>
                {'b) Die Volatilitätsanpassung ist eine dauerhafte Anpassung. Lebensversicherer, mit langfristigen Garantien, können kurzfristige Wertschwankungen der Kapitalanlagen "aussitzen" und müssen Buchverluste nicht realisieren. Mit der Volatilitätsanpassung (VA) werden Unter- oder Überbewertungen von Zinspapieren ausgeglichen.'}
            </Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>
                {'c) Die Übergangsmaßnahme für versicherungstechnische Rückstellungen ermöglicht den Versicherern eine schrittweise Anwendung der Solvency-II-Vorschriften über einen festen Zeitraum von 16 Jahren bis max 31.12.2031.'}
            </Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>
                {'b) + c) Die Übergangsmaßnahmen (ÜM) können auch zusammen mit der Volatilitätsanpassung (VA) genutzt werden. Die meisten Lebensversicherer wenden beide Maßnahmen gleichzeitig an.'}
            </Text>
            <Whitespace height={6} />
            <Text style={{ fontWeight: 'bold' }}>
                {'Die Lebensversicherer der Generali Deutschland wenden die Übergangsmaßnahmen nicht an. Sie haben sich statt dessen nur für die Volatilitätsanpassung enschieden.'}
            </Text>
        </Section>


    </Page>
);

export default PdfLegend1;
